import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Loader from './src/components/loader';
import { loadState } from './src/utils/localStorage';
import Keycloak from 'keycloak-js';

export const wrapRootElement = ({ element }) => {
  const selectedRealm = loadState('realm', 'STRING');
  const clientId = loadState('clientId', 'STRING');
  const token = loadState('token', 'STRING');
  const idToken = loadState('idToken', 'STRING');
  const refreshToken = loadState('refreshToken', 'STRING');

  const tokenLogger = (tokens) => {
    tokens.token && localStorage.setItem('token', tokens.token);
    tokens.refreshToken && localStorage.setItem('refreshToken', tokens.refreshToken);
    tokens.idToken && localStorage.setItem('idToken', tokens.idToken);
  };

  return (
    <ReactKeycloakProvider 
      authClient={
        new Keycloak({
          realm: selectedRealm || 'care',
          url: `https://keycloak.radius-stage.all.health/`,
          clientId: clientId || 'website-email',
        })
      }
      initOptions={{
        onLoad: "check-sso",
        checkLoginIframe: false,
        token: token,
        idToken: idToken,
        refreshToken: refreshToken,
      }}
      autoRefreshToken={true}
      onTokens={tokenLogger}
      LoadingComponent={<Loader />}
    >
      {element}
    </ReactKeycloakProvider>
  );
};
