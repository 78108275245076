export const isBrowser = () => typeof window !== 'undefined';

export const clearCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });
};

export const clearSession = () => {
  if (isBrowser()) {
    clearCookies();
    sessionStorage.clear();
    localStorage.clear();
  }
};
