// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-clinical-science-jsx": () => import("./../src/pages/clinical-science.jsx" /* webpackChunkName: "component---src-pages-clinical-science-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-sign-jsx": () => import("./../src/pages/sign.jsx" /* webpackChunkName: "component---src-pages-sign-jsx" */),
  "component---src-pages-solutions-jsx": () => import("./../src/pages/solutions.jsx" /* webpackChunkName: "component---src-pages-solutions-jsx" */),
  "component---src-pages-technology-jsx": () => import("./../src/pages/technology.jsx" /* webpackChunkName: "component---src-pages-technology-jsx" */),
  "component---src-pages-what-it-means-for-you-jsx": () => import("./../src/pages/what-it-means-for-you.jsx" /* webpackChunkName: "component---src-pages-what-it-means-for-you-jsx" */)
}

