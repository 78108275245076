import { isBrowser } from '../services/keycloak';

export const loadState = (stateName, type) => {
  if (isBrowser()) {
    try {
      const serializedState = localStorage.getItem(stateName);
      if (serializedState === null || serializedState === undefined || serializedState === 'undefined') {
        return undefined;
      }
      if (type === 'STRING') {
        return serializedState;
      }
      return JSON.parse(serializedState);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  return undefined;
};

export const saveState = (stateName, state) => {
  if (isBrowser()) {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(stateName, serializedState);
    } catch (error) {
      console.error(error);
    }
  }
};

export const fetchJsonFile = (url) => fetch(url).then((res) => res.json());
