import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default () => (
  <div>
    <CircularProgress
      color="inherit"
      size={40}
      style={{
        position: 'absolute', top: '50%', left: '50%', height: '40px', width: '40px'
      }}
    />
  </div>
);
